import React from "react";
import PropTypes from "prop-types";
import {
  ComponentGroup,
  FinalFormField,
  Fonts,
  ChevronDownIcon,
  ThumbsUpIcon,
  Card,
  ColorPalette,
  CardStyles,
} from "yuka";
import styled from "styled-components";

import { required, isEmail } from "utils/form/inputValidations";

import SPVNDA from "./SPVNDA";

const NDAContent = styled.div`
  margin: 0 16px;
  overflow-y: scroll;
  max-height: 250px;
`;

const StyledScrollText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const NdaFieldSet = () => {
  const [disabled, setDisabled] = React.useState(true);

  const onScroll = e => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      setDisabled(false);
    }
  };

  return (
    <>
      <Fonts.Headline3theme100>Before we can proceed...</Fonts.Headline3theme100>
      <Fonts.Headline2theme100>
        Please review and agree to the non-disclosure agreement below:
      </Fonts.Headline2theme100>
      <Card cardStyle={CardStyles.SECTIONED}>
        <NDAContent onScroll={onScroll}>
          <SPVNDA />
        </NDAContent>
        {disabled ? (
          <StyledScrollText>
            <Fonts.Overlinetheme80>Scroll all the way down to proceed</Fonts.Overlinetheme80>
            <ChevronDownIcon />
          </StyledScrollText>
        ) : (
          <StyledScrollText>
            <Fonts.Overlinetheme80>
              Done! Type in your name and email to accept the non-disclosure agreement
            </Fonts.Overlinetheme80>
            <ThumbsUpIcon color={ColorPalette.buy} />
          </StyledScrollText>
        )}
      </Card>
      <ComponentGroup>
        <FinalFormField
          name="name"
          label="Full Name*"
          placeholder="Enter your full name"
          validate={[required]}
          disabled={disabled}
        />
        <FinalFormField
          name="email"
          label="Email*"
          placeholder="Please enter your email"
          validate={[required, isEmail]}
          disabled={disabled}
        />
      </ComponentGroup>
    </>
  );
};

NdaFieldSet.propTypes = {
  spvInvite: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }),
};

export default NdaFieldSet;
