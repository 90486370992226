import React from "react";
import PropTypes from "prop-types";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import { StandardWizardActions, FinalFormField } from "yuka";

import { FinalFormControlledWizard } from "xeYuka";
import { DataTypes, QUERY_KEYS, useWrite } from "api";
import { compact } from "utils";
import LoadingSpinner from "utils/LoadingSpinner";
import { required } from "utils/form/inputValidations";

import NdaFieldSet from "./NdaFieldSet";
import PersonalInformationFieldSet from "./PersonalInformationFieldSet";

// Do not allow user to advance past this step. Tell them to check their email if we found an
// existing onboarding flow
const OnboardingRedirectPending = ({ checkEmail }) => (
  <>
    {checkEmail ? (
      <p>
        We noticed that you have started filling this out previously. In order to protect the
        privacy of your information, we have sent a personal link to your email to access your
        partially completed onboarding forms.
      </p>
    ) : (
      <LoadingSpinner />
    )}
    <FinalFormField name="__do_not_submit" validate={[required]} type="hidden" />
  </>
);

OnboardingRedirectPending.propTypes = {
  checkEmail: PropTypes.bool.isRequired,
};

const ZPFOnboardingWizard = ({ spvInvite }) => {
  const [loadedPrevious, setLoadedPrevious] = React.useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { onSubmit } = useWrite(
    QUERY_KEYS.SPV_INVITE.detail(spvInvite.apiId, ["start_onboarding"], {
      token: searchParams.get("token"),
    }),
    { method: "post" }
  );

  const wrappedOnSubmit = (values, ...params) => {
    const submitValues = {
      ...values,
      // Because this creates an onboarding request, this is the actual type to use
      apiType: DataTypes.ONBOARDING_REQUEST,
    };
    return onSubmit(submitValues, ...params).then(response => {
      if (response.data) {
        navigate(response.data.attributes.onboardingRequestUrl);
      } else {
        setLoadedPrevious(true);
      }
    });
  };

  const stepNames = compact([
    spvInvite.needsSignedNda ? "NDA" : "Personal Information",
    spvInvite.needsSignedNda && "SPV Documents",
    "Client's Domicile",
    "Individual, Entity or Trust",
    "IRA/ERISA Status",
    spvInvite.needsKyc && "Upload Documents",
    "Authorized Signatory",
    "Review & Sign",
  ]);

  return (
    <Form onSubmit={wrappedOnSubmit}>
      {({ submitting }) => (
        <FinalFormControlledWizard
          submitText="Next"
          title="Onboarding - SPV"
          stepNames={stepNames}
          currentStep={1}
          leftContent={
            <>
              {loadedPrevious ? (
                <OnboardingRedirectPending checkEmail={loadedPrevious} />
              ) : spvInvite.needsSignedNda ? (
                <NdaFieldSet spvInvite={spvInvite} />
              ) : (
                <PersonalInformationFieldSet />
              )}
              <StandardWizardActions />
              {submitting && <LoadingSpinner />}
            </>
          }
        />
      )}
    </Form>
  );
};

ZPFOnboardingWizard.propTypes = {
  spvInvite: PropTypes.shape({
    apiId: PropTypes.string,
    needsKyc: PropTypes.bool,
    needsSignedNda: PropTypes.bool,
    token: PropTypes.string,
  }).isRequired,
  relationships: PropTypes.object.isRequired,
};

export default ZPFOnboardingWizard;
